




















































































































































































































































































































































































































































//@import url(); 引入公共css类
.jianjie {
  background: url("../assets/images/jianjie/Group 1972.png");
  background-size: 100%;
  height: 450px;
}

.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
  border: none;
}

.image-button:focus {
  outline: none;
  box-shadow: none;
  outline-color: transparent !important;
  border-color: transparent !important;
}

/* 设置导航栏文字颜色为黑色 */
.navbar .el-menu-item {
  color: black;
}

/* 设置鼠标悬停时文字加粗 */
.navbar .el-menu-item:hover {
  font-weight: bold;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

.header {
  background: url("../assets/images/jianjie/Group 1968.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 700px;
  position: relative;
  z-index: 1;
}

.myimg {
  background-image: url('../assets/images/jianjie/Group 1998.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg2 {
  background-image: url('../assets/images/jianjie/Group 1999.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg3 {
  background-image: url('../assets/images/jianjie/Group 2000.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg4 {
  background-image: url('../assets/images/jianjie/Group 2001.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg5 {
  background-image: url('../assets/images/jianjie/Group 2019.png');
  background-size: 100%;
  height: 236px;
  width: 652px;
  
}

.myimg6 {
  background-image: url('../assets/images/jianjie/Group 2024.png');
  background-size: 100%;
  height: 212px;
  width: 412px;
}


.chanping {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
}

.chanquan {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
